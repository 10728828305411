<template>
  <v-container>
    <v-row class="d-flex flex-row container">
      <v-col cols="12" sm="6" md="6" lg="6">
        <h3
          class="font-weight-bold blue-grey--text font text-uppercase font-weight-medium font-md"
        >
          All Staff
        </h3>
      </v-col>
      <v-col cols="12" sm="6" md="6" lg="6">
        <v-card class="pa-2" style="height: 120px" outlined tile>
          <div class="createNew">
            <v-btn
              :to="{ name: 'admin.staffs.add' }"
              class="font font-md"
              color="primary"
            >
              Add New Staff
            </v-btn>
          </div>
          <div class="container">
            <v-col cols="12" sm="6" md="12">
              <v-text-field
                label="Search Staff....."
                dense
                v-model="search"
                outlined
              ></v-text-field>
            </v-col>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <v-row
      class="d-flex flex-row container"
      style="margin-top: -80px; margin-right:10px"
    >
      <v-col cols="12" sm="12" lg="12" md="12" xl="12">
        <v-toolbar
          color="transparent"
          flat
          elevation="0"
          class="mb-6 title mt-2"
          height="55px"
        >
        </v-toolbar>
        <v-data-table
          :headers="headers"
          :items="staffList"
          :loading="pageLoading"
          :server-items-length="paginate.total"
          :options.sync="options"
          class="font box-shadow-light font-weight-medium text-capitalize  mx-1 mt-n2"
          loading-text="Loading... Please wait"
          :search="search"
          :footer-props="{
            itemsPerPageOptions: [10, 15, 20],
          }"
        >
          <template #item.avatar="{item}">
            <v-avatar color="primary" size="40" class="ma-1 font">
              <span class="white--text subtitle-1 ">{{
                item.name | firstLetterFunction
              }}</span>
            </v-avatar>
          </template>
          <template #item.accountNumber="{item}">
            <v-btn small text color="primary">
              <span class=" font font-md">{{ item.accountNumber }}</span>
            </v-btn>
          </template>
          <template #item.actions="{item}">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  :to="{
                    name: 'admin.staffs.add',
                    query: {
                      id: item._id,
                      action: 'edit',
                    },
                  }"
                  v-on="on"
                  icon
                  fab
                  small
                >
                  <i class="material-icons-outlined font-md">edit</i>
                </v-btn>
              </template>
              <span class=" font font-sm">Edit Staff Member</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  v-on="on"
                  :loading="isLoading"
                  :disabled="profile.id === item._id"
                  @click="$store.dispatch('staffs/delete', item._id)"
                  icon
                  fab
                  small
                >
                  <i class="material-icons-outlined font-md">delete</i>
                </v-btn>
              </template>
              <span class=" font font-sm">Delete Staff Member</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <SnackBar
      :showSnackBar="showSnackBar"
      :message="message"
      :status="status"
    />
  </v-container>
</template>

<script>
import { loadComponent } from '@/utils/helpers';
import { mapGetters } from 'vuex';
import SnackBarMixins from '../../../../mixins/SnackBarMIxins';
export default {
  name: 'AllStaffs',
  data() {
    return {
      options: {},
      search: '',
    };
  },
  components: {
    SnackBar: loadComponent(`component/SnackBar`),
  },

  computed: {
    ...mapGetters({
      allStaff: 'staffs/getAllStaffs',
      pageLoading: 'getIsPageLoading',
      isLoading: 'getIsLoading',
      paginate: 'staffs/getPagination',
      profile: 'auth/getUserDetails',
    }),
    form() {
      return {
        name: this.name,
        phone: this.phone,
        accountNumber: this.accountNumber,
      };
    },
    staffList() {
      return this.allStaff.filter(searchTerm => {
        return (
          searchTerm.name.toLowerCase().indexOf(this.search.toLowerCase()) !==
          -1
        );
      });
    },

    headers() {
      return [
        {
          text: '',
          value: 'avatar',
          filterable: false,
          sortable: false,
        },
        {
          text: 'Staff Name',
          value: 'name',
          filterable: true,
          sortable: false,
        },
        {
          text: 'Account Number',
          value: 'accountNumber',
          filterable: false,
          sortable: false,
        },
        { text: 'Phone', value: 'phone', filterable: false, sortable: false },

        {
          text: 'Actions',
          value: 'actions',
        },
      ];
    },
  },
  filters: {
    status(value) {
      return !value ? 'Active' : 'Inactive';
    },
    firstLetterFunction(value) {
      return value.charAt(0).toUpperCase();
    },
  },
  mixins: [SnackBarMixins],
  watch: {
    options: {
      handler(value) {
        const { itemsPerPage, page } = value;
        const query = `?page=${page}&size=${itemsPerPage}`;
        this.$store.dispatch('staffs/list', query);
      },
      deep: true,
    },
  },

  methods: {},
  created() {
    const query = `?page=1&size=10`;
    this.$store.dispatch('staffs/list', query);
  },
};
</script>

<style scoped>
.createNew {
  display: flex;
  flex-direction: row;
  wrap: nowrap;
  justify-content: flex-end;
  align-items: stretch;
  alignt-content: stretch;
  margin-right: 25px;
}

.box {
  height: auto;
  width: auto;
  padding: 5%;
}
</style>
