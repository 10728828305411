export default {
  computed: {
    showSnackBar: {
      get() {
        return this.$store.getters['getSnackBarState'];
      },
      set(value) {
        this.$store.commit('UPDATE_SNACKBAR', value, { root: true });
      },
    },
    message: {
      get() {
        return this.$store.getters['getSnackBarMessage'];
      },
      set(value) {
        this.$store.commit('UPDATE_MESSAGE', value, { root: true });
      },
    },
    status: {
      get() {
        return this.$store.getters['getSnackBarStatus'];
      },
      set(value) {
        this.$store.dispatch('UPDATE_STATUS', value, { root: true });
      },
    },
  },
};
